import React from 'react';
import styled from 'styled-components';

const Step = ({ step, label, active = false, complete = false }) => (
  <StepStyle active={active} complete={complete}>
    
    {complete ? <StepCheckMark /> : <StepNumber active={active}>{step}</StepNumber>}

    <StepLabel active={active} complete={complete}>{label}</StepLabel>

  </StepStyle>
);

const StepCheckMark = () => (
  <StepCheckMarkStyle>
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="22px" height="17px" viewBox="0 0 22 17" version="1.1">
      <title>Check</title>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Breadcrumb/-2/Mobile/Dark-Blue" transform="translate(-49.000000, -27.000000)" fill="#FFFFFF" fillRule="nonzero">
          <polygon id="Path" points="67.7040915 27 70.1909913 29.338166 56.9295002 43.72973 49 35.7201056 51.40238 33.2934505 56.8418401 38.787874" />
        </g>
      </g>
    </svg>
  </StepCheckMarkStyle>
);

const StepStyle = styled.div`
  z-index: 2;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) perspective(1000px);
    width: 40px;
    height: 40px;
    border:2px solid  ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.blueDark};
    border-radius: 50%;
    transition: 0.3s ease;

    background-color: ${props => (props.active || props.complete) && props.theme.colors.blue};
    border: ${props => (props.active || props.complete) && `2px solid ${props.theme.colors.blue}`};

  }

  @media (max-width: 767px) {
    &:after {
      width: 40px;
      height: 40px;
    }
  }
`;



const StepNumber = styled.span`
  color:  ${props => props.theme.colors.blueDark};
  transition: 0.3s ease;
  display: block;
  transform: translate3d(0, 0, 0) scale(1) perspective(1000px);
  color:  ${props => props.active && props.theme.colors.white};
`;

const StepLabel = styled.div`
  position: absolute;
  top: calc(100% + 8px);
  left: 50%;
  transform: translateX(-50%) perspective(1000px);
  white-space: nowrap;
  font-size: 12px;
  font-weight: 300;
  text-transform: capitalize;
  color: #fff;
  transition: 0.3s ease;
  margin-top: 2px;
  color: ${props => (props.active || props.complete) && props.theme.colors.blue};
`;

const StepCheckMarkStyle = styled.div`
  padding-top: 2px;
`;


export default Step;