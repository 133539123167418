import styled from 'styled-components';
import { Bubble } from './index';

export const BottomPanel = styled.div`
  @media (min-width: 767px) { display: none; }
  & ${Bubble} {
    padding-bottom: ${props => props.theme.spacing.base * 4}px;
    text-align: center;
    font-size: 14px;
    max-width: 85%;
    margin: 0 auto;
    position: relative;
  }
`;

export const RightPanel = styled.div`
  display: none;
  max-width: 40%;
  @media (min-width: 767px) {
    display: block;
    padding-top: ${props => props.theme.spacing.base * 10}px;
    padding-left: ${props => props.theme.spacing.base * 2}px;
  }

  @media (min-width: 1000px) {
    padding-top: ${props => props.theme.spacing.base * 17}px;
    padding-left: ${props => props.theme.spacing.base * 2}px;
  }

  & ${Bubble} {
    position: relative;
    margin-bottom: ${props => props.theme.spacing.base * 2}px;
    font-weight: 500;
    text-align: center;
    font-size: 16px;
    background-color: ${props => props.theme.colors.grey3};
    padding: 20px;
    border-radius: 30px;
  }

  & ${Bubble}:after {
    content: "";
    bottom: -9px;
    right: -16px;
    z-index: -1;
    position: absolute;
    border: 0 solid transparent;
    border-top: 16px solid #dddddd;
    border-radius: 0 28px 0;
    width: 23px;
    height: 42px;
    transform: rotate(90deg) scaleY(-1);
  }

`;