import styled from 'styled-components';

// TODO: check in on the widths and heights, do we need them?
// ideally we want to keep these as generic as possible 
// and extend with styled(Button)``; to add special case styles. 
const Button = styled.button`
  min-width: 220px;
  min-height: 42px;
  max-width: 320px;
  background-color: ${props => props.theme.colors.blue};
  border: 1px solid ${props => props.theme.colors.blue};
  color: ${props => props.theme.colors.white};

  &:hover { color: ${props => props.theme.colors.white}; }
`;

export default Button;