
import styled from 'styled-components';


export const FinePrint = styled.div`
  font-size: 14px;
  & p { margin-bottom: ${props => props.theme.spacing.base}px; }
  & p:last-of-type { margin-bottom: ${props => props.theme.spacing.base * 3}px; }
`;

export const Prompt = styled.div`
  font-size: 21px;
  font-weight: 500;
  text-align: center;
  @media(min-width: 400px) {
    text-align: left;
  }
`;

export const Bubble = styled.div``;

export const Feefo = styled.div`
  & .prompt-html--inline-mob { display: block; }
  & li {
    padding-bottom: ${props => props.theme.spacing.base}px;
  }
`;