import React, { useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby-link';

import { submitLead } from '../../services/whitelabel-api';

import RenderOnClientOnly from '../utils/RenderOnClientOnly';
import { publishEvent } from '../../services/eventBus/';

import Container from '../Container';
import Button from '../Buttons/Button';
import BackLink from '../Buttons/BackLink';
import ProgressBar from './ProgressBar';
import Spinner from '../Loaders/Spinner';
import { useJourneyContext } from '../Contexts/JourneyContext/JourneyContext';

import { Prompt, Bubble, Feefo, FinePrint } from './Prompts/';
import { RightPanel, BottomPanel } from './Prompts/PromptPanels';
import CONFIG from '../../utils/config';
import { isSSR } from '../../utils/renderContext';

// SurveyJs is Client side only, we lazy load it to avoid issues with SSR
const LazySurveyJS = React.lazy(() => import('./SurveyJS.js'));

const JourneyStartSpinner = () => (
	<Spinner
		message={
			<>
				One moment while we <br />
				prepare your journey
			</>
		}
	/>
);
const SubmissionSpinner = () => (
	<Spinner
		message={
			<>
				Please wait while we build <b /> your report
			</>
		}
	/>
);
// TODO: this has gotten quite unwieldy it needs to be broken down.
const SurveyJsJourney = () => {
	const surveyRef = useRef(null);
	const [activeQ, setActiveQ] = useState({ pageNumber: 0 });
	const [furthestProgress, setFurthestProgress] = useState(0);
	const [loading, setLoading] = useState(false);
	const [submissionErrors, setSubmissionErrors] = useState([]);
	const journeyContext = useJourneyContext();

	const { surveyConfig } = journeyContext.journey;

	useMemo(() => updateGlobalValues(journeyContext), [journeyContext]);

	publishEvent('start-gtm-tracking', { id: CONFIG.GTM_CONTAINER_ID });

	// Track page number for use outside the survey.
	const handlePageChange = (surveyModel, evnt) => {
		const newPageNum = surveyModel.questionJson.pages.findIndex(
			(page) => page.name === evnt.newCurrentPage.name
		);
		// disable auto next page for fields with manual next button.
		if (surveyRef?.current?.survey) {
			surveyRef.current.survey.goNextPageAutomatic = !surveyConfig.behaviour.manualNext.includes(
				newPageNum
			);
		}
		setActiveQ({
			pageNumber: newPageNum,
			page: { ...surveyModel.questionJson.pages[newPageNum] }
		});
		if (newPageNum > furthestProgress) {
			setFurthestProgress(newPageNum);
		}

		if (newPageNum !== 0) {
			publishEvent('step-complete', {
				step: evnt.oldCurrentPage.name,
				sequence: newPageNum,
				stepsInSequence: surveyModel.questionJson.pages.length,
				stepTitle: surveyModel.questionJson.pages[newPageNum].title || '',
				stepDescription:
					surveyModel.questionJson.pages[newPageNum].description || '',
				appId: journeyContext.source.appId
			});
		}
	};

	// trigger before onComplete so we can stop it via allowComplete.
	const handleSubmission = async (result, options) => {
		setLoading(true);
		return submitLead(
			result.data,
			journeyContext.source.appId,
			journeyContext.source.cid,
			journeyContext.source.campaignID
		)
			.then((res) => {
				options.allowComplete = true;
				publishEvent('submission', {
					event: 'submission',
					appId: journeyContext.source.appId,
					report: res.data.report
				});
				navigate(`/complete?id=${res.data.id}&appId=${res.data.appId}`, {
					state: { response: res.data }
				});
			})
			.catch(() => {
				options.allowComplete = false;
				surveyRef.current?.survey.clear(false, true); // preserve form state we're going back.
				surveyRef.current.survey.currentPageNo = activeQ.pageNumber; // return to last page we were on.
				setSubmissionErrors(['Sorry we were unable to build your report.']);
				setLoading(false);
			});
	};

	const finePrint = activeQ.page?.elements?.filter(
		(el) => el.type === 'html' && el.name.includes('fineprint-')
	);
	const prompts = activeQ.page?.elements?.filter(
		(el) => el.type === 'html' && el.name.includes('prompt-')
	);
	const bubbles = activeQ.page?.elements?.filter(
		(el) => el.type === 'html' && el.name.includes('bubble-')
	);
	const feefo = activeQ.page?.elements?.find(
		(el) => el.type === 'html' && el.name.includes('feefo-')
	);

	const pages = surveyConfig?.surveyJson?.pages;

	const categories = Object.values(
		pages?.reduce((acc, page, index) => {
			const label = page?.name?.split('-')[0];
			return {
				...acc,
				[label]: {
					...(acc[label] || {}),
					label,
					step:
						typeof acc[label]?.step !== 'undefined'
							? acc[label].step
							: Object.keys(acc).length,
					firstQNum:
						typeof acc[label]?.firstQNum !== 'undefined'
							? acc[label]?.firstQNum
							: index,
					lastQNum: index
				}
			};
		}, {})
	)
		.filter((category) => category.label !== 'uncategorised')
		.sort((a, b) => a.step - b.step);

	return (
		<>
			<SurveyWrap numPrompts={bubbles?.length || 0}>
				<ProgressBar steps={categories} activeQ={activeQ.pageNumber} />

				{loading && (
					<LoadWrap>
						<SubmissionSpinner />
					</LoadWrap>
				)}

				<Container className={`${loading ? 'hidden' : ''}`}>
					<RenderOnClientOnly>
						<React.Suspense fallback={<JourneyStartSpinner />}>
							<SurveyContainer>
								<div>
									<StyledBackLink
										style={{
											visibility: activeQ.pageNumber > 0 ? 'visible' : 'hidden'
										}}
										onClick={() => surveyRef.current?.survey.prevPage()}
									/>

									<div style={{ position: 'relative' }}>
										<LazySurveyJS // SurveyJs library JS Mount
											ref={surveyRef}
											questionJson={surveyConfig?.surveyJson}
											onCompleting={handleSubmission}
											onCurrentPageChanged={handlePageChange}
										/>
									</div>

									<SurveyButtonContainer>
										{
											// Next page button selectively rendered
											(surveyConfig?.behaviour.manualNext.includes(
												activeQ.pageNumber
											) ||
												furthestProgress > activeQ.pageNumber) && (
												<Button
													className="btn"
													onClick={() => {
														surveyRef.current?.survey.nextPage();
													}}
												>
													Next
												</Button>
											)
										}

										{
											// Submission error display
											submissionErrors.length > 0 &&
												surveyRef.current?.survey.isLastPage &&
												submissionErrors
													.slice(0, 1)
													.map((error) => (
														<SubmissionError key={`submission-error-${error}`}>
															{error}
														</SubmissionError>
													))
										}

										{
											// Form submit.
											surveyRef.current?.survey.isLastPage && (
												<Button
													className="btn"
													onClick={() => {
														surveyRef.current?.survey.completeLastPage();
													}}
												>
													Get My Report
												</Button>
											)
										}
									</SurveyButtonContainer>

									{finePrint && (
										<FinePrint>
											{finePrint?.map((prompt) => (
												<p
													key={`fp-${prompt.name}`}
													dangerouslySetInnerHTML={{ __html: prompt.html }}
												/>
											))}
										</FinePrint>
									)}

									{prompts?.map((prompt) => (
										<Prompt
											key={`prompt-${prompt.name}`}
											dangerouslySetInnerHTML={{ __html: prompt.html }}
										/>
									))}
								</div>

								{(bubbles?.length > 0 || typeof feefo != 'undefined') && (
									<RightPanel>
										{feefo && (
											<Feefo dangerouslySetInnerHTML={{ __html: feefo.html }} />
										)}
										{bubbles?.map((prompt) => (
											<Bubble
												key={`panel-bubble-${prompt.name}`}
												dangerouslySetInnerHTML={{ __html: prompt.html }}
											/>
										))}
									</RightPanel>
								)}
							</SurveyContainer>
						</React.Suspense>
					</RenderOnClientOnly>
				</Container>
			</SurveyWrap>

			{bubbles?.length > 0 && (
				<BottomPanel>
					{bubbles?.map((prompt) => (
						<Bubble
							key={`bottomPanel-bubble-${prompt.name}`}
							dangerouslySetInnerHTML={{ __html: prompt.html }}
						/>
					))}
				</BottomPanel>
			)}
		</>
	);
};

/**
 * Function used to pass data between react and surveyjs
 */
function updateGlobalValues(journeyContext) {
	if (isSSR()) return;
	if (!window.__wlGlobal) window.__wlGlobal = {};
	window.__wlGlobal.appId = journeyContext.source.appId;
	window.__wlGlobal.useData8 = journeyContext.journey.useData8;
	window.__wlGlobal.data8Type = journeyContext.journey.data8Type;
}

const SurveyWrap = styled.div`
	padding-bottom: ${(props) => props.theme.spacing.base * 5}px;
	min-height: ${(props) => (props.numPrompts > 1 ? 60 : 80)}vh;
	@media only screen and (min-width: 1000px) {
		padding-bottom: ${(props) => props.theme.spacing.base * 8}px;
	}
`;

const SurveyContainer = styled(Container)`
	display: block;
	@media (min-width: 767px) {
		display: flex;
		justify-content: space-between;
	}
`;

const LoadWrap = styled(Container)`
	padding-top: ${(props) => props.theme.spacing.base * 6}px;
`;

const StyledBackLink = styled(BackLink)`
	margin-top: ${(props) => props.theme.spacing.base * 2}px;
	@media (min-width: 1000px) {
		margin-top: ${(props) => props.theme.spacing.base * 4}px;
	}
`;

// surveyjs has a page margin on all elements, this should realign us with the content
export const SurveyButtonContainer = styled.div`
	& ${Button} {
		width: 100%;
		margin-bottom: ${(props) => props.theme.spacing.base * 4}px;
		margin-top: ${(props) => props.theme.spacing.base * 3}px;
	}
`;

const SubmissionError = styled.p`
	color: ${(props) => props.theme.colors.red};
	font-size: 14px;
	padding-top: ${(props) => props.theme.spacing.base}px;
`;

export default SurveyJsJourney;
