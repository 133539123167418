import React from 'react';
import styled from 'styled-components';

import Step from './Step';

const STEP_BUFFER = 0.06;

// TODO: we can likely simplify the markup for this now the styled components are used.
// TODO: Refactor progress bar to separate logic from render
// Note the progress bar at time of writing finishes before the questions finish.
// the progress logic may need to be simplified due to this
const ProgressBar = ({ steps = [], activeQ = 0 }) => (
  <CheckoutProgress progress={getProgressWithBuffer(steps, activeQ, true, STEP_BUFFER)}>
      <ProgressBarInner>

        {steps.map((step) =>
          <Step
            key={`progress-step-${step.step}`}
            step={step.step + 1}
            label={step.label}
            active={activeQ >= step.firstQNum}
            complete={activeQ > step.lastQNum}
          />)}
      
      </ProgressBarInner>
  </CheckoutProgress>
);


// TODO: rework this its not great.
// This logic is a little convoluted but it looks quite cool :)
// gets question weighted progress with extra added for each step.
function getProgressWithBuffer(steps, activeQuestion, activeIsDone, stepBuffer) {
  const bufferProgress = steps.filter((step) => activeQuestion >= step.firstQNum).length * stepBuffer;
  const actualProgress = getProgress(steps, activeQuestion, activeIsDone = false);
  const progressPerc =  bufferProgress + actualProgress;
  return progressPerc > 1 ? 1 : progressPerc
}

// gets question progress for each step. 
function progressThroughStep(step, activeQuestion) {
  if(activeQuestion <= step.firstQNum) return 0;
  if(activeQuestion > step.lastQNum) return 1;
  
  const numQuestions = (step.lastQNum - step.firstQNum) + 1; // 0 indexed
  const questionsDone = activeQuestion - step.firstQNum;
  const percDone = questionsDone / numQuestions;
  return percDone;
}


// percentage progress weighted by step size
function getProgress(steps, activeQuestion, activeIsDone) {
  if(activeIsDone) activeQuestion += 1;
  const totalProgress = steps.reduce((acc, curr) => acc + progressThroughStep(curr, activeQuestion), 0);
  const progressPerc = totalProgress / steps.length;
  return progressPerc;
} 

const CheckoutProgress = styled.div`
  background-color: ${props => props.theme.colors.blueDark};
  padding: 20px 10% 38px;
  font-size: 21px;
  position: relative;
  font-weight: 500;

  &:before {
    content: "";
    position: absolute;
    left: 10%;
    top: 40%;
    height: 2px;
    width: 80%;
    background-color: ${props => props.theme.colors.white};
    transform: translateY(-50%) perspective(1000px);
  }

  &:after {
    content: "";
    position: absolute;
    left: 10%;
    top: 40%;
    height: 2px;
    width: 80%;
    background-color:  ${props => props.theme.colors.blue};
    transform: scaleX(${props => isNaN(props.progress) ? 0 : props.progress}) translateY(-50%) perspective(1000px);
    transform-origin: left center;
    transition: transform 0.5s ease;
  }

`;


const ProgressBarInner = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default ProgressBar;
