import React from 'react';
import { Helmet } from 'react-helmet';

import JourneyStyles from '../components/SurveyJsJourney/JourneyStyles';
import SurveyJsJourney from '../components/SurveyJsJourney/';

const JourneyPage = () => {
  return (
    <>
      <Helmet>
        <style>
          {
          /*
            this is a workaround for the surveyjs library and not the correct pattern to follow.
          */
            JourneyStyles
          }
        </style>
      </Helmet>

      <SurveyJsJourney />
    </>
  );
}


export default JourneyPage;