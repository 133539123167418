import { theme } from '../../Theme';
/**
 * This file is used this way as a workaround for injecting the surveyjs with styles
 * should contain only styles required for the surveyjs journey itself and kept to a minimum.
 */ 

const JourneyStyles = `
/*************************************************
 *  START PAGE LAYOUT
 *************************************************/

  /* add spacing to the top of the form, allows us to avoid show/hiding it during load */
  .sv_main  {
    margin-top: ${theme.spacing.base * 3}px; 
    /* around 320px - 325px the survey overflow the windows ignoring container padding */
    overflow: hidden;
  }
  @media(min-width: 350px) {
    .sv_main  { overflow: visible; }
  }

  @media(min-width: 1000px) {
    .sv_main { margin-top: ${theme.spacing.base * 8}px; }
  }

  @media(min-width: 767px) {
    .sv_p_root.sv_p_root--has-prompt {
      max-width: 450px
    }
  }


  /*************************************************
   *  END PAGE LAYOUT
   *************************************************/

  /* Text Elements */
  .sv-title,
  .sv_page_title,
  .sv_q_title {
    max-width: 400px;
    font-weight: 500;
    font-size: 21px;
    margin-bottom:  ${theme.spacing.base * 1.5}px;
    color: ${theme.colors.black};
  }
  .sv_page_title--no-desc { margin-bottom: ${theme.spacing.base * 4}px; }

  @media(min-width: 768px) {
    .sv_page_title { position: relative; }
    .sv_page_title:before {
      content:'';
      width: 10px;
      height: 60px;
      border-radius: 10px;
      background-color: ${theme.colors.blueDark};
      left: -24px;
      position: absolute;
    }
  }

  .sv_page_description {
    margin-bottom: ${theme.spacing.base * 4}px;
    max-width: 410px;  
  }
  @media(min-width: 1000px) {
    .sv_page_description { max-width: 450px; }
  }

  /*************************************************
   *  START ERRORS
   *************************************************/ 

  .sv_q_erbox {
    color: ${theme.colors.red};
    font-size: 14px;
    padding-top: ${theme.spacing.base}px;
  }

  .sv_q_text_root + .sv_q_erbox {
    min-height: ${theme.spacing.base * 7}px;
  }

  .sv_radiogroup-fieldset + .sv_q_erbox  {
    padding-top: 0;
    min-height: ${theme.spacing.base * 4}px;
  }

  
  /*************************************************
   *  START TEXT INPUT - INC NUMBER, PHONE, EMAIL
   *************************************************/
  input.sv_q_text_root {
    width: 100%;
    max-width: 97.7%;
    padding: ${theme.spacing.base * 2.5}px;
    margin-bottom: ${theme.spacing.base * 7}px;
    border-radius: 5px;
    border: 1px solid ${theme.colors.grey1};
    outline: none;
    background-color: ${theme.colors.white} !important;
    line-height: 17px;
  }
  @media(min-width: 350px) {
    input.sv_q_text_root { max-width: 315px; }
  }

  input.sv_q_text_root:focus {
    border: 1px solid ${theme.colors.blue};
  }

  .sv_q_has_error .sv_q_text_root {
    border: 1px solid ${theme.colors.red};
    margin-bottom: 0;
    overflow: visible;
  }
  
  /* NUMBER */
  input[type=number] {
    padding-left: 30px;
    background-position: left;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='50px' width='50px'><text x='18' y='30' fill='0d0e0e' font-size='17' font-family='arial'>£</text></svg>")
  }

  /* hides arrows on number inputs. */
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type=number] { -moz-appearance: textfield; }

  /* END NUMBER */


  /*************************************************
   *  END TEXT INPUT
   *************************************************/



  /*************************************************
   *  START RADIOGROUP
   *************************************************/
  .label { margin: 0; }

  .sv_qstn fieldset.sv_radiogroup-fieldset {
    display: flex;
    flex-wrap: wrap;
    max-width: 315px;
    justify-content: space-between;
    margin-bottom: ${theme.spacing.base * 3}px;
  }
  .sv_qstn.sv_q_has_error fieldset.sv_radiogroup-fieldset {
    margin-bottom: 0;
  }

  .sv_qstn fieldset.sv_radiogroup-fieldset .sv_q_radiogroup.sv-q-col-1 {
    display: inline;
    padding-right: 0;
    width: auto;
  }

  .sv_q_radiogroup_control_item { display: none; } /* hides input element itself */

  /* NB this is picking up some bootstrap defaults */
  .sv_q_radiogroup_label {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 2px;
    margin-bottom: ${theme.spacing.base}px;
    width: 150px;
    height: 50px;
    border: 1px solid ${theme.colors.grey1};
    border-radius: 5px;
    background: ${theme.colors.white};
  }


  .sv_q_radiogroup_label:hover {
    border: 1px solid ${theme.colors.greenPale};
    background-color: ${theme.colors.greenPale};
    cursor: pointer;
  }

  .sv_q_radiogroup_label--checked {
    border: 1px solid ${theme.colors.greenDark};
    background-color: ${theme.colors.greenDark};
    color: ${theme.colors.white};
  }

  /*************************************************
   *  END RADIOGROUP
   *************************************************/



   /*************************************************
    * START PROMPT SPECIAL CASE
    *************************************************/
    .prompt-html--inline-mob li {
      padding-bottom: ${theme.spacing.base}px;
    }

    @media(min-width: 767px) {
      .prompt-html--inline-mob { display: none };
    }


   /*************************************************
    *  END PROMPT SPECIAL CASE
    *************************************************/

`;



export default JourneyStyles;
